import { ReactElement, ReactNode } from 'react';
import { Options } from '@splidejs/splide';
import { TypeClassName } from '@/types';

export const enum StylePagination {
  PAGE = 'PAGE',
  PAGINATION = 'PAGINATION',
  ARTICLES_PAGINATION = "ARTICLES_PAGINATION",
}

type TypeExtensionsSlider = 'grid' | 'none';

export interface TypeSlideProps extends TypeClassName {
  /**
   * ¿Es el fondo oscuro o claro?
   */
  negative?: boolean;
  /**
   * Caption del slide
   */
  caption?: string;
  /**
   * Permite pasar el contenido.
   */
  children: ReactElement;
}

export interface TypeSliderProductProps extends TypeClassName {
  /**
   * Altura de la slide.
   */
  height?: string | number;
  /**
   * Permite configurar el slide según un breakpoint especifico.
   */
  breakpoints?: Options['breakpoints'];
  /**
   * Permite pasar las slides.
   */
  children: ReactNode | ReactNode[];
  /**
   * ¿Quieres centrar el contenido de la slide?.
   */
  centerSlide?: boolean;
  /**
   * Número de slides a mostrar.
   */
  perPage?: number;
  /**
   * ¿Que tipo de slide quieres?
   */
  type?: Options['type'];
  /**
   * Permite modificar el Gap de los slides.
   */
  gap?: Options['gap'];

  /**
   * ¿Quieres activar alguna extensión?
   */
  extensions?: TypeExtensionsSlider[];
  /**
   * Opciones de la extensión grid
   */
  grid?: Options['grid'];
}
